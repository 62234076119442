import Button from '@mui/material/Button';
import { useState, useEffect, useContext} from 'react'
import styled from 'styled-components';
import * as serLib from '@emurgo/cardano-serialization-lib-asmjs/cardano_serialization_lib';
import {Buffer} from 'buffer'
import CircularProgress from '@mui/material/CircularProgress';

import Grid from '@mui/material/Grid'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import './ClaimClay.css'

import {MainClaimContext} from 'components/ClaimContext'

//import {MainClayPayContext} from '../../Context/ClayPayContext'

import CustomCircularProgress from './CustomCircularProgress';

// import cn_meta from './json/cn_db.json'

// import trousers_info from './json/trousers_info.json'


// import './Balance.css'

import close from './Images/Close.svg'



const root_url = 'https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/'

  const ConnectButton = styled.button`
    position: relative;
    width: ${props => props.matchesS ? '220px' : '150px'};
    height: ${props => props.matchesS ? '70px' : '50px' };
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2d4359;
    overflow: hidden;
    border-radius: 18px;
    opacity: ${props => props.theme.op}; 
    border:0px;

    font-family: shortstack;

    cursor: pointer;

    margin-bottom: 5px;
    
  :before
  {
    content: '';
    position: absolute;
    width: 250px;
    height: 140%;
    background: linear-gradient(-180deg,#f98763,#53C9EE);
    opacity: ${props => props.theme.op}; 
    animation: ${props => props.theme.anim}; 
  }

  @keyframes animate 
  {
    0%
    {
      transform: rotate(0deg);
    }
    100%
    {
      transform: rotate(360deg);
    }
  }
  :after
  {
    content: '';
    position: absolute;
    inset : 2px;
    background: linear-gradient(30deg, rgba(47,53,85,1) 0%, rgba(29,58,61,1) 100%);
    box-shadow: inset -7px -7px 38px rgba(0, 0, 0), inset 3px 1px 10px rgba(255, 255, 255, 0.4);
    border-radius: 18px;
    z-index: 1;
    border:0px;
  }

  :hover
  {
    position: relative;
    width: ${props => props.matchesS ? '220px' : '150px'};
    height: ${props => props.matchesS ? '70px' : '50px' };
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2d4359;
    overflow: hidden;
    border-radius: 18px;
    opacity: ${props => props.theme.op}; 
  }


  :hover:before
  {
    content: '';
    position: absolute;
    width: ${props => props.matchesS ? '250px' : '180px'};
    height: 250%;
    background: linear-gradient(120deg,#f98763,#53C9EE);
    opacity: ${props => props.theme.op}; 
    animation: ${props => props.theme.anim}; 
  }
  `
const theme = {
  anim: 'animate 7s linear infinite',
  op: 0.9
}

const DropDown = styled.div`
  

` 

const WalletConnected = styled.button`

 position: relative;
    width: 220px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('https://claynation.nyc3.cdn.digitaloceanspaces.com/public_files/public/wallet_connected.svg');
    overflow: hidden;
    border-radius: 18px;
    opacity: ${props => props.theme.op}; 
    border: 0px;
    cursor: pointer;

    background-repeat: no-repeat;
    background-size: cover;

    margin-bottom: 5px;

`

const ConnectedAcoount = styled.button`
    position: relative;
    width: 200px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2d4359;
    overflow: hidden;
    border-radius: 18px;
    opacity: ${props => props.theme.op}; 
    border:0px;

    cursor: pointer;
    
  :before
  {
    content: '';
    position: absolute;
    width: 250px;
    height: 140%;
    background: linear-gradient(-180deg,#f98763,#53C9EE);
    opacity: ${props => props.theme.op}; 
    animation: ${props => props.theme.anim}; 
  }

  @keyframes animate 
  {
    0%
    {
      transform: rotate(0deg);
    }
    100%
    {
      transform: rotate(360deg);
    }
  }
  :after
  {
    content: '';
    position: absolute;
    inset : 2px;
    background: linear-gradient(30deg, rgba(47,53,85,1) 0%, rgba(29,58,61,1) 100%);
    box-shadow: inset -7px -7px 38px rgba(0, 0, 0), inset 3px 1px 10px rgba(255, 255, 255, 0.4);
    border-radius: 18px;
    z-index: 1;
    border:0px;
  }

  :hover
  {
    position: relative;
    width: 200px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2d4359;
    overflow: hidden;
    border-radius: 18px;
    opacity: ${props => props.theme.op}; 
  }


  :hover:before
  {
    content: '';
    position: absolute;
    width: 250px;
    height: 250%;
    background: linear-gradient(120deg,#f98763,#53C9EE);
    opacity: ${props => props.theme.op}; 
    animation: ${props => props.theme.anim}; 
  }
` 

const DropDownItem = styled.div`
  position: relative;
  width: 220px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,0.25);
  overflow: hidden;
  border-radius: 0px;
  opacity: 0.92;
  cursor: pointer;

  z-index: 5;

  backdrop-filter: 'blur(80px)';



:hover
{
  content: '';
  position: relative;
  width: 220px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, rgba(132,211,255,1) 0%, rgba(148,130,244,1) 49%, rgba(246,141,141,1) 100%);
  opacity: 0.92;

}


:after
{
  content: '';
  position: absolute;
  inset : 2px;
  background: linear-gradient(138deg, rgba(53,61,104,1) 0%, rgba(51,57,77,1) 44%, rgba(29,58,61,1) 100%);
  border-radius: 0px;
  z-index: 1;
  backdrop-filter: 'blur(80px)';

}
`


const ButtonText = styled.h1`
  position: relative;
  font-size: 1.8em;
  font-family: 'shortstack';
  letter-spacing: 0.1em;
  text-shadow: 2px 2px 2px rgba(0,0,0,0.5);
  color: #fff;
  z-index: 4;
`


const PopUp = styled.div`

    
  background: linear-gradient(186deg, #303847 -15.07%, #282A2F 111.3%);
  box-shadow: 0px 4px 5px 2px rgba(44, 46, 50, 0.25);

  border-radius: 32px;
  
  width: 40%;
  height: 65%;

  margin-top: 100px;

  max-width: 600px;
  max-height: 900px;

  padding: 22px;

  
  align-items: center;
  justify-content: center;
  font-size: 25px;
  display: flex;
  z-index: 10;
  color: white;
  backdrop-filter: blur(20px);
  -webkit-animation: scale-in 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
          animation: scale-in 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;



  @media screen and (max-width: 680px) {
    min-width: 300px;
    min-height: 450px;

    width: 60%;
    height: 65%;
  }

  @media screen and (min-width: 680px) {
    min-width: 400px;
    min-height: 550px;

    width: 38%;
  }


  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

`



const PopUpInner = styled.div`

  margin-top: 30px;

  width: 95%;
  height: 95%;

  background-color: transparent;
  border: none;
  color: var(--clayblue-primary);

  font-size: 1.5em;
  opacity: 0.9;   
  cursor: pointer;
  font-family: ShortStack;

  padding-bottom: 20px;
  
`


const CloseButton = styled.button`
  position: absolute;
  top: 5px;
  right: 26px;
  background-color: transparent;
  border: none;
  color: var(--clayblue-primary);

  font-size: 1.3em;
  opacity: 0.9;   
  cursor: pointer;
  font-family: ShortStack;

  &:hover{
    text-shadow: 2px 2px 0px rgba(0, 0, 0, 0.462);
  }


  @media screen and (max-width: 680px) {
    font-size: 1.1em;
  }

  @media screen and (min-width: 680px) {
    font-size: 1.3em;
  }
`

const WalletOptions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 100%;
`

const WalletButton = styled.button`
  font-family: ButtonFont;  
  width: 100%;

  max-width: 320px;

  height: 20%;
  cursor: pointer;
  align-items: center;
  align-content: center;
  text-align: center;
  justify-content: center;
  display: flex;
  padding: 20px;
  border-radius: 22px;
  border: none;
  margin-top: 10px;
  font-size: 1.2rem;
  background: linear-gradient(90deg, rgba(28, 37, 52, 1) 0%, rgba(28, 37, 52, 0.6) 100%);
  color: rgba(255, 255, 255, 0.7);
  letter-spacing: 0.1rem;
  text-shadow: 2px 2px 0px black;


 

  &:hover{

    border: 2px solid #F6CACA;
    outline: 2px solid #F6CACA;

    transition: all 0.3s ease-in-out;

    box-shadow: 0px 0px 21px #FAE2E2;
  }

  @media screen and (max-width: 680px) {
    font-size: 1.2rem;
  }

  @media screen and (min-width: 680px) {
    
    font-size: 1.5rem;
  }




  background: var(--background);
  color: rgba(255, 255, 255, 0.7);
  
  letter-spacing: 0.1rem;
  text-shadow: 2px 2px 0px black;
  font-family: ButtonFont;


  border: 2px solid #F6CACA;
  

`


const WalletButton_old = styled.button`
  font-family: ButtonFont;  
  width: 100%;

  max-width: 320px;

  height: 20%;
  cursor: pointer;
  align-items: center;
  align-content: center;
  text-align: center;
  justify-content: center;
  display: flex;
  padding: 20px;
  border-radius: 22px;
  border: none;
  margin-top: 10px;
  font-size: 1.2rem;
  background: linear-gradient(90deg, rgba(28, 37, 52, 1) 0%, rgba(28, 37, 52, 0.6) 100%);
  color: rgba(255, 255, 255, 0.7);
  letter-spacing: 0.1rem;
  text-shadow: 2px 2px 0px black;

  &:hover{
    box-shadow: 0px 0px 20px var(--clayblue-secondary);
    outline: 2px solid var(--clayblue-secondary);
  }

  @media screen and (max-width: 680px) {
    font-size: 1.2rem;
  }

  @media screen and (min-width: 680px) {
    
    font-size: 1.5rem;
  }




  background: var(--background);
  color: rgba(255, 255, 255, 0.7);
  border: 2px solid var(--clayblue-secondary);
  letter-spacing: 0.1rem;
  text-shadow: 2px 2px 0px black;
  font-family: ButtonFont;

`


const WalletLogo = styled.img`
  width: 30px; 
  height: auto; 
  padding-right: 30px;
`



const WalletHeading = styled.div`





font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 36px;
line-height: 44px;
text-align: center;
text-transform: capitalize;

color: #D9D9D9;

margin-bottom: 5px;



`




function Connect(props) {

  const {isPopUpOpen, setIsPopUpOpen, isBusy, setIsBusy, walletToEnable, setWalletToEnable, updateWallet, setUpdateWallet} = props

  const walletLogos = {
    nami:'nami.svg',
    eternl:'eternl.png',
    yoroi:'yoroi.svg',
    flint:'flint.svg',
    gerowallet:'gerowallet.svg',
    lace: 'lace.svg',
    vespr: 'vespr.png',
    typhon: 'typhon.svg',
    tokeo: 'tokeo.svg',
    nufi: 'nufi.png'
  }



  useEffect(() => {

    if (isBusy){
      setIsPopUpOpen(false)
    }

  },[isBusy, walletToEnable])





  return (
     <>


    {
      true ?

        <PopUp>
          <PopUpInner>

           

             <CloseButton type="button" onClick={() => setIsPopUpOpen(false)}><img src={close} style={{width:'50', height:'auto', zIndex:'10'}} /></CloseButton> 

            <div style={{display:'flex', alignItems:'center', justifyContent:'center', height:'100%', width:'100%'}}>
            <div style={{overflow:'scroll', height:'95%', width: '100%'}}>

            <WalletOptions>

              <WalletHeading>Select Wallet</WalletHeading>

              <div style={{height:'10px', width:'100%', backgroundColor:'', position:'relative', display:'flex'}}>
                <div style={{height:'10px', width:'200%'}}> 

                </div>
              </div>


              <WalletButton
                onClick={()=>{
                  setIsBusy(true)
                  setWalletToEnable('nami')}
                }
              >

              {isBusy && walletToEnable == 'nami' ? <div style={{marginLeft:'0px', marginRight:'30px',zIndex:'5'}}> <CircularProgress style={{width:'30px',height:'30px', zIndex:'5', color:'#53c8ee'}} /> </div>:

                <WalletLogo src={root_url + walletLogos.nami} />
              }
                CONNECT NAMI
              </WalletButton>

              <WalletButton
                onClick={()=>{
                  setIsBusy(true)
                  setWalletToEnable('eternl')}
                }
              >

              {isBusy && walletToEnable == 'eternl' ? <div style={{marginLeft:'20px', marginRight:'30px',zIndex:'5'}}> <CircularProgress style={{width:'30px',height:'30px', zIndex:'5', color:'#53c8ee'}} /> </div>:

                <WalletLogo src={root_url + walletLogos.eternl} style={{marginLeft:'20px'}} />
              }
                CONNECT ETERNL
              </WalletButton>

              <WalletButton
                onClick={()=>{
                  setIsBusy(true)
                  setWalletToEnable('gerowallet')}
                }
              >
              {isBusy && walletToEnable == 'gerowallet' ? <div style={{marginLeft:'0px', marginRight:'30px',zIndex:'5'}}> <CircularProgress style={{width:'30px',height:'30px', zIndex:'5', color:'#53c8ee'}} /> </div>:

                <WalletLogo src={root_url + walletLogos.gerowallet} />
              }
                CONNECT GERO
              </WalletButton>

              <WalletButton
                onClick={()=>{
                  setIsBusy(true)
                  setWalletToEnable('lace')}
                }
              >
              {isBusy && walletToEnable == 'lace' ? <div style={{marginLeft:'0px', marginRight:'30px',zIndex:'5'}}> <CircularProgress style={{width:'30px',height:'30px', zIndex:'5', color:'#53c8ee'}} /> </div>:

                <WalletLogo src={root_url + walletLogos.lace} />
              }
                CONNECT LACE
              </WalletButton>

              <WalletButton
                onClick={()=>{
                  setIsBusy(true)
                  setWalletToEnable('yoroi')}
                }
              >

              {isBusy && walletToEnable == 'yoroi' ? <div style={{marginLeft:'0px', marginRight:'30px',zIndex:'5'}}> <CircularProgress style={{width:'30px',height:'30px', zIndex:'5', color:'#53c8ee'}} /> </div>:

                <WalletLogo src={root_url + walletLogos.yoroi} />

              }

                CONNECT YOROI
              </WalletButton>

              {/*/  <WalletButton
              //   onClick={()=>{
              //     setIsBusy(true)
              //     setWalletToEnable('flint')}
              //   }
              //  >

              //  {isBusy && walletToEnable == 'flint' ? <div style={{marginLeft:'0px', marginRight:'20px',zIndex:'5'}}> <CircularProgress style={{width:'30px',height:'30px', zIndex:'5', color:'#53c8ee'}} /> </div>:

              //   <WalletLogo src={root_url + walletLogos.flint} style={{width:'20px'}} />

              // }
              //   CONNECT FLINT
              // </WalletButton>*/}


              <WalletButton
                onClick={()=>{
                  setIsBusy(true)
                  setWalletToEnable('typhoncip30')}
                }
               >

               {isBusy && walletToEnable == 'typhoncip30' ? <div style={{marginLeft:'0px', marginRight:'20px',zIndex:'5'}}> <CircularProgress style={{width:'30px',height:'30px', zIndex:'5', color:'#53c8ee'}} /> </div>:

                <WalletLogo src={root_url + walletLogos.typhon} style={{width:'30px', marginLeft:'20px'}} />

              }
                CONNECT TYPHON
              </WalletButton>


              <WalletButton
                onClick={()=>{
                  setIsBusy(true)
                  setWalletToEnable('vespr')}
                }

               >

               {isBusy && walletToEnable == 'vespr' ? <div style={{marginLeft:'0px', marginRight:'20px',zIndex:'5'}}> <CircularProgress style={{width:'30px',height:'30px', zIndex:'5', color:'#53c8ee'}} /> </div>:

                <WalletLogo src={root_url + walletLogos.vespr} style={{width:'30px', marginLeft:'5px'}} />

              }
                CONNECT VESPR
              </WalletButton>





               <WalletButton
                onClick={()=>{
                  setIsBusy(true)
                  setWalletToEnable('tokeo')}
                }

               >

               {isBusy && walletToEnable == 'tokeo' ? <div style={{marginLeft:'0px', marginRight:'20px',zIndex:'5'}}> <CircularProgress style={{width:'30px',height:'30px', zIndex:'5', color:'#53c8ee'}} /> </div>:

                <WalletLogo src={root_url + walletLogos.tokeo} style={{width:'30px', marginLeft:'5px'}} />

              }
                CONNECT TOKEO
              </WalletButton>






              <WalletButton
                onClick={()=>{
                  setIsBusy(true)
                  setWalletToEnable('nufi')}
                }

               >

               {isBusy && walletToEnable == 'nufi' ? <div style={{marginLeft:'0px', marginRight:'20px',zIndex:'5'}}> <CircularProgress style={{width:'30px',height:'30px', zIndex:'5', color:'#53c8ee'}} /> </div>:

                <WalletLogo src={root_url + walletLogos.nufi} style={{width:'30px', marginLeft:'-10px'}} />

              }
                CONNECT NUFI
              </WalletButton>






              <div style={{height:'10px', width:'100%', backgroundColor:'', position:'relative', display:'flex'}}>
                <div style={{height:'10px', width:'200%'}}> 

                </div>
              </div>


            </WalletOptions>

            
            </div>
            </div>

          </PopUpInner>
        </PopUp>

      : null
    }


    </>

  );
}

export default Connect;
